

















































import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'CreatePassword',
  components: { SubmitButton },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      showPassword: false,
    }
  },
  methods: {
    toggleShowPassword(): void {
      this.showPassword = !this.showPassword
    },
    submitNewPassword(): void {
      if (this.password === this.passwordConfirm) {
        console.log(this.password + '  new password')
      } else {
        this.$toast.error("Password fields doesn't match")
      }
    },
  },
  computed: {
    passwordFieldType(): string {
      return this.showPassword ? 'text' : 'password'
    },
    passwordFieldIcon(): string {
      return this.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    },
  },
})
